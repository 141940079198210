import { useEffect, useState, createContext, useContext } from 'react';
import { supabase } from '../supabase-client';
import { handleAuthChange } from '../helpers';

export const UserContext = createContext();

const fetchToken = async (token, companyId, userId) => {
  try {
    await fetch('/api/fnx/refresh_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token,
        companyid: companyId,
        userid: userId,
      },
      credentials: 'same-origin',
    });
  } catch (error) {
    throw new Error('Error getting refresh tokens in useUser');
  }
};

export const UserContextProvider = (props) => {
  const [userLoaded, setUserLoaded] = useState(false);
  const [session, setSession] = useState(supabase.auth.session());
  const [user, setUser] = useState(session?.user ?? null);
  // const [session, setSession] = useState(null);
  // const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [subscriptionItems, setSubscriptionItems] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    // const session = supabase.auth.session();
    // setSession(session);
    // setUser(session?.user ?? null);
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('event :>> ', event);
        // The function below sets the user on the server, to be able to check user status
        // on the server side, before page load
        handleAuthChange(event, session);
        setSession(session);
        setUser(session?.user ?? null);
      }
    );
    // If we have a active company set in localstorage, set the value
    // this is needed to have the correct company selected after page refresh
    const activeCompany = localStorage.getItem('activeCompany') || null;
    if (activeCompany) {
      const parsedCompany = JSON.parse(activeCompany);
      if (parsedCompany.system === 'fnx') {
        // fetchToken(session?.access_token, parsedCompany.id, user?.id);
      }
      setSelectedCompany(parsedCompany);
    }

    return () => {
      authListener.unsubscribe();
    };
  }, []);

  const getUserDetails = () => supabase.from('users').select('*').single();

  const getCompaniesSubscription = (companyId) => {
    if (companyId) {
      return supabase
        .from('subscriptions')
        .select('*, prices(*, products(*))')
        .in('status', ['trialing', 'active', 'past_due'])
        .eq('metadata->>company', companyId);
    }
    return supabase
      .from('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active', 'past_due']);
  };

  // Get all subscription items that are active, and it's connected subscription metadata
  // You only get subscription items connected to the uuid of the logged in user
  const getSubscriptionItems = () =>
    supabase
      .from('subscription_items')
      .select('*, subscriptions(metadata)')
      .in('status', ['active', 'past_due']);

  // Filter only the active companies subscription items. Can't filter in the db query since its a foreign table
  const filterSubItems = (subItemsArray, companyId) =>
    subItemsArray.filter(
      (item) => Number(item.subscriptions.metadata.company) === companyId
    );

  useEffect(() => {
    if (user && selectedCompany) {
      Promise.allSettled([
        getUserDetails(),
        getCompaniesSubscription(selectedCompany?.id),
        getSubscriptionItems(),
      ]).then((results) => {
        setUserDetails(results[0].value.data);
        setSubscriptions(results[1].value.data);
        setSubscriptionItems(
          filterSubItems(results[2].value.data, selectedCompany?.id)
        );
        setUserLoaded(true);
      });
    }
  }, [user, selectedCompany]);

  const value = {
    session,
    user,
    userLoaded,
    userDetails,
    userLoaded,
    selectedCompany,
    subscriptions,
    subscriptionItems,
    changeEmail: async (options) => {
      const updated = await supabase.auth.update({
        email: options,
      });
      return updated;
    },
    signIn: (options) => supabase.auth.signIn(options),
    signUp: ({ redirectTo, ...rest }) =>
      supabase.auth.signUp(rest, {
        redirectTo: redirectTo,
      }),
    signOut: () => {
      setUserDetails(null);
      setSubscriptions(null);
      setSubscriptionItems(null);
      setSelectedCompany(null);
      localStorage.removeItem('activeCompany');
      return supabase.auth.signOut();
    },
    changeCompany: async (company) => {
      if (company === undefined) return;
      if (company.system === 'fnx') {
        fetchToken(session?.access_token, company?.id, user?.id);
      }
      localStorage.setItem('activeCompany', JSON.stringify(company));
      setSelectedCompany(company);
      // Fetch the subscriptions connected to the newly set company, the filter is done in the db query
      const activeCompSubs = await getCompaniesSubscription(company.id);
      const activeCompSubsItems = await getSubscriptionItems();
      const filteredSubItems = filterSubItems(
        activeCompSubsItems.data,
        company.id
      );
      setSubscriptions(activeCompSubs.data);
      setSubscriptionItems(filteredSubItems);
    },
  };
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
