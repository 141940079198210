import { QueryClient, QueryClientProvider } from 'react-query';
import 'tailwindcss/tailwind.css';
import { UserContextProvider } from '../utils/hooks/useUser';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import NextNProgress from 'nextjs-progressbar';

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-MS9ZZ6P' });
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60,
      },
    },
  });
  return (
    <UserContextProvider>
      <QueryClientProvider client={queryClient}>
        <NextNProgress color='#2F81FF' />
        <Component {...pageProps} />
        <Toaster />
      </QueryClientProvider>
    </UserContextProvider>
  );
}

export default MyApp;
