export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ''
      ? process.env.URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
      ? process.env.VERCEL_URL
      : window.location.origin;

  return url.includes('http') ? url : `https://${url}`;
};

export async function handleAuthChange(event, session) {
  try {
    await fetch('/api/auth', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
      body: JSON.stringify({ event, session }),
    });
  } catch (error) {
    console.log('Error POST /api/auth', error);
    throw new Error(error);
  }
}

export const todaysDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if needed

  return `${year}-${month}-${day}`;
};

export const oneMonthAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if needed

  return `${year}-${month}-${day}`;
};

export const toDateTime = (secs) => {
  var t = new Date('1970-01-01T00:00:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export const priceString = (amount) =>
  new Intl.NumberFormat('se-SE', {
    style: 'currency',
    currency: 'sek',
    minimumFractionDigits: 0,
  }).format(amount / 100);

export const dateString = (date) =>
  // Format the date to yyyy-MM-dd without using Intl.DateTimeFormat
  new Date(date).toISOString().split('T')[0];

export const getErrorMessage = (code) => {
  // Code is postgres errorcodes or error codes from Kivra, check: https://www.postgresql.org/docs/9.2/errcodes-appendix.html
  switch (code) {
    case '401':
      return 'Din session har gått ut. Logga in och försök igen.';
    case '40915':
      return 'Detta org.nummer finns upplagt hos Kivra sedan tidigare. Vi har fått informationen och löser detta. Vi återkommer till dig via mail inom kort!';
    case '23505':
      return 'Företag med denna databasnyckel finns redan upplagd';
  }
};

export async function postToDiscord(message) {
  await fetch(process.env.DISCORD_WEBHOOK_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: message,
    }),
  });
}

// Min 8, max 20 letter password, with at least a symbol, upper and lower case letters and a number
export function checkPassword(str) {
  var re =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$%&?"])[a-zA-Z0-9!#$%&?]{8,20}$/;
  return re.test(str);
}

export function isValidSSN(ssn) {
  const dayOfBirth = ssn.split('-')[0].slice(-2);
  if (dayOfBirth > 31) return false;
  return true;
}

export function isCorporate(organisationNumber) {
  // Checks the organisation number to see what company form the customer is
  // https://bolagsverket.se/ff/foretagsformer/organisationsnummer-1.7902
  const result =
    organisationNumber?.startsWith('5') ||
    organisationNumber?.startsWith('9') ||
    organisationNumber?.startsWith('7') ||
    organisationNumber?.startsWith('8');
  return result;
}
