import { createClient } from '@supabase/supabase-js';

export const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
  {
    autoRefreshToken: false,
  }
);

export const updateUserName = async (user, name) => {
  const { data, error } = await supabase
    .from('users')
    .update({
      full_name: name,
    })
    .eq('id', user.id);
  return {
    data,
    error,
  };
};

// The RLS policy on the companies table will make sure we only get companies that the
// logged in user has a connection to in the connections table
export const getConnectedCompanies = async () => {
  const { data, error } = await supabase.from('companies').select(
    `
        id,
        name,
        integrator_key,
        system,
        tenants (
          tenant_key
      )
  `
  );
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

export const getActiveProductsWithPrices = async () => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.active', true)
    .order('metadata->index')
    .order('unit_amount', { foreignTable: 'prices' });

  if (error) {
    console.log(error.message);
    throw error;
  }

  return data || [];
};
